import React, { useState } from 'react';
import './UserPage.css';
import logo_icon from '../Assets/Logo.png';
import howtouse_washapp from '../Assets/WashApp.mp4';
import profile_icon from '../Assets/person.png';
import laundry_icon from '../Assets/laundry.png';
import oder_icon from '../Assets/order.png';
import facebook_icon from '../Assets/facebook.png';
import instagram_icon from '../Assets/instagram.png';
import QuantitySelector from './QuantitySelector';

export const UserPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [isWashModalOpen, setWashModalOpen] = useState(false); // New state for wash modal
  const [profileImage, setProfileImage] = useState(null);
  const [profile, setProfile] = useState({
    name: "",
    gender: "",
    birthday: "",
    address: "",
    phone: ""
  });

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleProfileClick = () => {
    setProfileModalOpen(true);
  };

  const handleWashClick = () => {
    setWashModalOpen(true); // Open wash modal when wash button is clicked
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(URL.createObjectURL(file));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = () => {
    // Handle profile change logic here
    console.log("Profile updated:", profile);
    setProfileModalOpen(false);
  };

  return (
    <div className="user-section-container">
      <button className="toggle-btn" onClick={toggleSidebar}>
        {isOpen ? 'Thank You!' : 'Wash Now!'}
      </button>

      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-content">

          <button className="profile-container" onClick={handleProfileClick}>
            <img src={profile_icon} alt="Profile"></img>
            <h1>Profile</h1>
          </button>

          <button className="wash-container" onClick={handleWashClick}>
            <img src={laundry_icon} alt="Laundry"></img>
            <h1>Wash</h1>
          </button>

          <button className="order-status-container">
            <img src={oder_icon} alt="Order Status"></img>
            <h1>Order Status</h1>
          </button>
        </div>
      </div>

      <header className="userheader">
        <button className="Logout">Logout</button>
        <img src={logo_icon} alt="Logo" />
      </header>

      <div className="user-main">
        <div className="connect-with-us">
          <h2 className="contact-heading">Connect with Us</h2>
          <div className="socials">
            <div className="social-item">
              <div className="social-text">
                <h3>Facebook</h3>
                <p>WashApp.Official</p>
              </div>
              <img src={facebook_icon} alt="Facebook" className="icon" />
            </div>
            <div className="social-item">
              <div className="social-text">
                <h3>Instagram</h3>
                <p>WashAppIG.Official</p>
              </div>
              <img src={instagram_icon} alt="Instagram" className="icon" />
            </div>
          </div>
          <div className="contact-info">
            <div className="contact-item">
              <h3>Mobile Number</h3>
              <p>09322394441</p>
            </div>
            <div className="contact-item">
              <h3>Email Address</h3>
              <p>WashApp@gmail.com</p>
            </div>
          </div>
        </div>

        <div className="user-main-right">
          <h1>How to use WashApp?</h1>
          <div className="video-container">
            <video width="480" height="350" controls>
              <source src={howtouse_washapp} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p>
              This section is thoughtfully crafted to guide users through the
              WashApp application with ease, ensuring that every feature and
              function is accessible and user-friendly. Our goal is to enhance
              the overall user experience by providing clear instructions and
              intuitive navigation, making it simple for users to manage their
              laundry services effortlessly.
            </p>
          </div>
        </div>
      </div>

      {/* Profile Modal */}
      {isProfileModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="back-button" onClick={() => setProfileModalOpen(false)}>
              Back
            </button>

            <div className="profile-modal-body">
              <h2 className="edit-profile-header">Edit Profile</h2>

              <div className="profile-image">
                <label htmlFor="file-input">
                  <img
                    src={profileImage || profile_icon}
                    alt="Profile"
                    className="circle-img"
                  />
                </label>
                <input
                  id="file-input"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </div>

              <div className="profile-info">
                <label>
                  Name:
                  <input
                    type="text"
                    name="name"
                    value={profile.name}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Gender:
                  <select
                    name="gender"
                    value={profile.gender}
                    onChange={handleChange}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </label>
                <label>
                  Birthday:
                  <input
                    type="date"
                    name="birthday"
                    value={profile.birthday}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Address:
                  <input
                    type="text"
                    name="address"
                    value={profile.address}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Phone No.:
                  <input
                    type="text"
                    name="phone"
                    value={profile.phone}
                    onChange={handleChange}
                  />
                </label>
              </div>

              <button className="apply-button" onClick={handleSubmit}>
                Apply Change
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Wash Modal */}
      {isWashModalOpen && (
        <div className="modalone">
          <div className="modalone-content">
            
            <button className="modalone-close" onClick={() => setWashModalOpen(false)}>Back</button>
            <h2>Categories</h2>
            <div className="categories-container">
  <div className="category-item">
    <button className="category-button"></button>
    <p className="category-text">TOPS</p>
  </div>
  <div className="category-item">
    <button className="category-button"></button>
    <p className="category-text">BOTTOMS</p>
  </div>
  <div className="category-item">
    <button className="category-button"></button>
    <p className="category-text">BLANKETS</p>
  </div>
  <div className="category-item">
    <button className="category-button"></button>
    <p className="category-text">COMPORTERS</p>
  </div>
  <div className="modaltwo">
    <h1>Indoor Tops</h1>
  <div className="quantity-selector-wrapper">
        
        <QuantitySelector itemName="XS-S" imageSrc={facebook_icon} />
        <QuantitySelector itemName="M-L" imageSrc={facebook_icon} />
        <QuantitySelector itemName="XL-XXL" imageSrc={facebook_icon}/>
      </div>
      <div className="modaltwoheader">
        <h1>Outdoor Tops</h1>
      <div className="modaltwotwo">
      <QuantitySelector itemName="XS-S" imageSrc="path_to_image.png" />
        <QuantitySelector itemName="M-L" imageSrc="path_to_another_image.png" />
        <QuantitySelector itemName="XL-XXL" imageSrc="path_to_another_image.png" />
      </div>
      </div>
      
      </div>
      <div className="modalthree">
    <h1>Indoor Bottoms</h1>
  <div className="quantity-selector-wrapper">
        
        <QuantitySelector itemName="XS-S" imageSrc="path_to_image.png" />
        <QuantitySelector itemName="M-L" imageSrc="path_to_another_image.png" />
        <QuantitySelector itemName="XL-XXL" imageSrc="path_to_another_image.png" />
      </div>
      <div className="modalthreeheader">
        <h1>Outdoor Bottoms</h1>
      <div className="modaltwotwo">
      <QuantitySelector itemName="XS-S" imageSrc="path_to_image.png" />
        <QuantitySelector itemName="M-L" imageSrc="path_to_another_image.png" />
        <QuantitySelector itemName="XL-XXL" imageSrc="path_to_another_image.png" />
      </div>
      </div>
      
      </div>
      <div className="modalfour">
    <h1>Blankets</h1>
  <div className="quantity-selector-wrapper">
        
        <QuantitySelector itemName="Thick" imageSrc="path_to_image.png" />
        <QuantitySelector itemName="Thin" imageSrc="path_to_another_image.png" />
        
      </div>
      <div className="modalfourheader">
        <h1>Comporters</h1>
      <div className="modaltwotwo">
      <QuantitySelector itemName="Thick" imageSrc="path_to_image.png" />
        <QuantitySelector itemName="Thin" imageSrc="path_to_another_image.png" />
        
      </div>
      </div>
      
      </div>
</div>

            <button onClick={() => setWashModalOpen(false)}>Close Modal</button>
          </div>
          
        </div>
      )}
    </div>
  );
};
