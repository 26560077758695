
import './App.css';
import { UserPage } from './Components/UserPage/UserPage';

function App() {
  return (
    <div>
      <UserPage/>
    </div>
  );
}

export default App;
