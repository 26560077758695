import React, { useState } from 'react';
import './QuantitySelector.css'; // Make sure to create this file for CSS
const QuantitySelector = ({ itemName, imageSrc }) => {
  const [quantity, setQuantity] = useState(0);

  const increment = () => {
    setQuantity(quantity + 1);
  };

  const decrement = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div className="category-container">
    <div className="quantity-selector-container">
      <div className="image-container">
        <img src={imageSrc} alt={itemName} />
      </div>
      <p className="item-name">{itemName}</p>
      <div className="quantity-controls">
        <button onClick={decrement} className="decrement-btn">-</button>
        <div className="quantity-display">{quantity}</div>
        <button onClick={increment} className="increment-btn">+</button>
      </div>
      
      
    </div>
    </div>
    
    
  );
};

export default QuantitySelector;
